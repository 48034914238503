import { template as template_cd13d7a1b5204c09a9b5aca6403d809a } from "@ember/template-compiler";
const FKText = template_cd13d7a1b5204c09a9b5aca6403d809a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
