import { template as template_e1a56c910f8741bebb4db20256b369e3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e1a56c910f8741bebb4db20256b369e3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
